import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Services from "./components/Services";
import About from "./components/About";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="DK Groep: Uw partner in complete technische oplossingen. Van
                  strategisch advies en ontwerp tot de realisatie, beheer en onderhoud
                  van geïntegreerde technologische oplossingen. Wij nemen alle zorgen
                  uit handen en leveren samen met onze partners antwoorden op
                  maatschappelijke vraagstukken rond mobiliteit, milieu, zorg en
                  veiligheid."
        />
        <title>Toekomstige oplossingen mogelijk maken | DK Groep</title>
      </Helmet>
      <Router>
        <div className="App">
          <Nav />
          <div className="main-content">
            <Routes>
              <Route path="/" exact element={<Home />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
