import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/dkgroep-logo.png";

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="footer__wrapper">
            <div className="footer__left">
              <ul className="footer__list footer__list--left">
                <div className="footer__sublist">
                  <li className="footer__list--item">
                    <Link to="/" className="footer__item--logo">
                      <img
                        src={Logo}
                        className="footer__item--logo-img"
                        alt="Footer Logo DK Groep"
                      />
                    </Link>
                  </li>
                  <li className="footer__list--item">
                    <p className="footer__item--para">
                      Wij de techniek, u het resultaat. Samen succesvol!
                    </p>
                  </li>
                </div>
                <li className="footer__list--item footer__socials">
                  <a
                    href="https://www.facebook.com/DkAllroundServices"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon="fab fa-facebook-f" />
                  </a>
                  <a href="https://www.instagram.com/dkgroep/" target="_blank">
                    <FontAwesomeIcon icon="fab fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer__middle">
              <ul className="footer__list footer__list--left">
                <div className="footer__list--title">
                  <h3 className="footer__list--title-h3">DK Groep</h3>
                </div>
                <li className="footer__item">
                  <Link className="footer__item--link" to="/">
                    Home
                  </Link>
                </li>
                <li className="footer__item">
                  <Link className="footer__item--link" to="/#onsbedrijf">
                    Ons Bedrijf
                  </Link>
                </li>
                <li className="footer__item">
                  <Link className="footer__item--link" to="/#diensten">
                    Diensten
                  </Link>
                </li>
                <li className="footer__item">
                  <Link className="footer__item--link" to="/#contact">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer__right">
              <ul className="footer__list footer__list--left footer__noextend">
                <div className="footer__list--title">
                  <h3 className="footer__list--title-h3">Contact</h3>
                </div>
                <li className="footer__item">Rotterdamseweg 386b10</li>
                <li className="footer__item">2629 HG Delft</li>
                <li className="nav__list footer__item">
                  <span>T </span>
                  <a
                    href="tell:+0852736063"
                    className="nav__link footer__link--bold"
                  >
                    085 273 60 63
                  </a>
                </li>
                <li className="nav__list footer__item">
                  <span>E </span>
                  <a
                    href="mail:info@dkgroep.nl"
                    className="nav__link footer__link--bold"
                  >
                    info@dkgroep.nl
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="privacy">
        <div className="container">
          <div className="row">
            <div className="privacy__wrapper">
              <div className="privacy__left">
                <p className="privacy__para">
                  © {new Date().getFullYear()} DK Groep
                  <span className="bullet"></span>
                  <Link to="/privacy" className="privacy__link">
                    Privacystatement
                  </Link>
                </p>
              </div>
              <div className="privacy__right">
                <p className="privacy__para">
                  Website door{" "}
                  <Link
                    to="https://sennaoudshoorn.nl"
                    className="privacy__para--link"
                  >
                    Senna Oudshoorn
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
