import React, { useState, useEffect } from "react";
import Photo1 from "../assets/service--elektrotechniek.jpg";
import Photo2 from "../assets/service--beveiliging.jpg";
import Photo3 from "../assets/service--bouwkundig.jpg";
import Photo4 from "../assets/service--winstallatie.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Services = () => {
  let [currentService, setCurrentService] = useState(0);

  const servicesArray = [
    {
      key: 1,
      name: "Elektrotechniek",
      image: Photo1,
      description:
        "Zonder stroomvoorziening en verlichting kan geen enkele organisatie functioneren. Voedingen zijn nodig om allerlei apparatuur te laten werken die zorgt voor een comfortabele omgeving en het waarborgen van veiligheid.",
      color: "red",
    },
    {
      key: 2,
      name: "Beveiliging",
      image: Photo2,
      description:
        "U wilt toch ook ongewenste bezoekers uit uw pand weren? Zowel overdag als 's nachts wilt u inbraak voorkomen en de kans minimaliseren. Op basis van een goede inbraakbeveiliging en camerabeveiliging en bouwkundig advies bieden wij een totaal oplossing voor het beveiligen van uw pand.",
      color: "green",
    },
    {
      key: 3,
      name: "Bouwkundig",
      image: Photo3,
      description:
        "U wilt uw bedrijf of woning verbouwen of renoveren? Informeert u dan ook eens bij DK Allround Services. Wij werken hiervoor samen met een vaste gerenommeerde partner. U kunt bij ons terecht voor het plaatsen van een dakkapel, een dakopbouw, een serre, een aanbouw aan een keuken of woonkamer enz., enz.",
      color: "yellow",
    },
    {
      key: 4,
      name: "W. Installatie",
      image: Photo4,
      description:
        "DK allroundservices is er ook als het gaat om de verwarmingsinstallatie, loodgieterswerkzaamheden, sanitair en airconditioning. Zowel voor aanleg van nieuwe installaties als onderhoud en storingen.",
      color: "purple",
    },
  ];

  let rService = servicesArray[currentService];

  function nextService() {
    if (currentService == servicesArray.length) {
      setCurrentService((currentService = 0));
      nextService();
    } else {
      setCurrentService(currentService++);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      nextService();
    }, 15 * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="services" id="diensten">
      <div className="container">
        <div className="row">
          <h2 className="services__title">Diensten</h2>
          <div className="services__wrapper">
            <ul className="services__list">
              {servicesArray.map((service, index) => (
                <li
                  key={service.key}
                  className={"services__list--item " + service.color}
                >
                  <button
                    onClick={() => {
                      setCurrentService(index);
                    }}
                  >
                    {rService.name == service.name ? (
                      <b>
                        <FontAwesomeIcon
                          className="icon"
                          icon="fas fa-arrow-right"
                        />{" "}
                        {service.name}
                      </b>
                    ) : (
                      service.name
                    )}
                  </button>
                </li>
              ))}
            </ul>
            <div className="services__image">
              <img
                loading="lazy"
                src={rService.image}
                alt="Service Foto DK Groep"
                className="services__image--img"
              />
            </div>
            <div className={"services__description " + rService.color}>
              <h3 className="services__description--title">{rService.name}</h3>
              <p className="services__description--para">
                {rService.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
