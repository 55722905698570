import React from "react";

const Quote = () => {
  return (
    <section className="quote">
      <div className="container">
        <div className="row">
          <div className="quote__text">
            <p className="quote__text--para">
              Voor al uw <span>technologische</span> vraagstukken. Dat is wel zo makkelijk
              en vaak nog <span>goedkoper</span> ook!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Quote;
