import React from "react";
import LandingBanner from "../assets/landing--photo.jpg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HashLink as HashLink } from "react-router-hash-link";

const Landing = () => {
  return (
    <section className="landing">
      <div className="container">
        <div className="row">
          <div className="landing__title">
            <span className="landing__title--span">DK Groep</span>
            <h1 className="landing__title--head">
              Wij de techniek, u het <br />
              resultaat. Samen succesvol!
            </h1>
            <div className="btn__wrapper">
              <HashLink to="/#diensten" className="btn">
                Onze diensten
                <FontAwesomeIcon className="icon" icon="fas fa-arrow-right" />
              </HashLink>
            </div>
          </div>
        </div>
        <div className="landing__image">
          <img
            src={LandingBanner}
            alt="Banner DK Groep"
            className="landing__image--img"
            loading="lazy"
          />
          {/* <ul className="landing__services">
              <li className="landing__services--service service--purple">
                <h3 className="service__title">Elektrotechniek</h3>
              </li>
              <li className="landing__services--service service--red">
                <h3 className="service__title">Beveiliging</h3>
              </li>
              <li className="landing__services--service service--green">
                <h3 className="service__title">Bouwkundig</h3>
              </li>
              <li className="landing__services--service service--yellow">
                <h3 className="service__title">W. Installatie</h3>
              </li>
            </ul> */}
        </div>
      </div>
    </section>
  );
};

export default Landing;
