import React from "react";
import Logo from "../assets/dkgroep-logo.png";
import MenuImage from "../assets/menu--photo.jpg";
import { Link } from "react-router-dom";
import { HashLink as HashLink } from "react-router-hash-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Nav = () => {
  const hamburger = document.querySelectorAll(".hamburger");

  function toggleMenu() {
    document.body.classList.toggle("menu--open");

    hamburger.forEach((item) => {
      item.classList.toggle("is-active");
    });
  }

  return (
    <nav>
      <div className="nav__container container nav__exclusive">
        <Link to="/" className="nav__logo--container">
          <img src={Logo} className="nav__logo" alt="DK Groep Logo" />
        </Link>
        <ul className="nav__links">
          <li className="nav__list nav__phone">
            <span>T </span>
            <a href="tell:+0852736063" className="nav__link">
              085 273 60 63
            </a>
          </li>
          <div className="nav__line"></div>
          <li className="nav__list">
            <Link className="nav__link" onClick={toggleMenu}>
              <button className="hamburger hamburger--collapse" type="button">
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              Menu
            </Link>
          </li>
        </ul>
      </div>
      <div className="menu__backdrop">
        <div className="nav__container container">
          <Link to="/" className="nav__logo--container">
            <img src={Logo} className="nav__logo" alt="DK Groep Logo" />
          </Link>
          <ul className="nav__links">
            <li className="nav__list nav__phone">
              <span>T </span>
              <a href="tel:+0852736063" className="nav__link">
                085 273 60 63
              </a>
            </li>
            <div className="nav__line"></div>
            <li className="nav__list">
              <Link className="nav__link" onClick={toggleMenu}>
                <button className="hamburger hamburger--collapse" type="button">
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
                Menu
              </Link>
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="menu__bar">
            <ul className="menu__contact--list">
              <li className="nav__list nav__phone contact__list--link">
                <span>T </span>
                <a href="tel:+0852736063" className="nav__link">
                  085 273 60 63
                </a>
              </li>
              <li className="nav__list nav__phone contact__list--link">
                <span>E </span>
                <a href="mailto:info@dkgroep.nl" className="nav__link">
                  info@dkgroep.nl
                </a>
              </li>
            </ul>
            <div className="menu__image">
              <img
                className="menu__image--img"
                src={MenuImage}
                alt="Menu Foto DK Groep"
                loading="lazy"
              />
            </div>
          </div>
          <ul className="menu__links">
            <Link className="menu__link" to="/" onClick={toggleMenu}>
              <li className="menu__list">
                Home <FontAwesomeIcon icon="fas fa-arrow-right" />
              </li>
            </Link>
            <HashLink
              to="/#onsbedrijf"
              className="menu__link"
              onClick={toggleMenu}
            >
              <li className="menu__list">
                Ons Bedrijf <FontAwesomeIcon icon="fas fa-arrow-right" />
              </li>
            </HashLink>
            <HashLink
              to="/#diensten"
              className="menu__link"
              onClick={toggleMenu}
            >
              <li className="menu__list">
                Diensten <FontAwesomeIcon icon="fas fa-arrow-right" />
              </li>
            </HashLink>
            <HashLink
              to="/#contact"
              className="menu__link"
              onClick={toggleMenu}
            >
              <li className="menu__list">
                Contact <FontAwesomeIcon icon="fas fa-arrow-right" />
              </li>
            </HashLink>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
