import React from "react";
import AboutImage from "../assets/about--photo.jpg";

export default function About() {
  return (
    <section className="about" id="onsbedrijf">
      <div className="container">
        <div className="row">
          <div className="about__wrapper">
            <div className="about__description">
              <h3 className="about__title">ONZE MISSIE</h3>
              <p className="about__para">
                Technologie is uit onze maatschappij niet meer weg te denken en
                zal in de toekomst een steeds grotere rol gaan spelen. Zonder
                technologie hebben we geen antwoord op actuele maatschappelijke
                vraagstukken zoals mobiliteit, milieu, zorg en veiligheid. DK
                Allround Services levert integrale oplossingen vanuit een
                multidisciplinaire aanpak: een combinatie van elektrotechniek,
                ICT, beveiliging, werktuigbouw en bouwkundige werkzaamheden. Wij
                bieden onze klanten complete technische dienstverlening: van
                consultancy en ontwerp tot realisatie, beheer en onderhoud. Dit
                in samenwerking met onze partners.
                <br /> <br />
                DK Allround Services, de naam zegt het al, is een van de meest
                complete dienstverleners op technologisch gebied en neemt u
                graag alle zorg uit handen. Van advies en ontwerp tot
                realisatie, beheer en onderhoud.
                <br /> <br />
                <b>
                  Al sinds <span>2009</span> de beste!
                </b>
              </p>
            </div>
            <div className="about__image">
              <img
                src={AboutImage}
                alt="Over ons Foto DK Groep"
                className="about__image--img"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
