import React from "react";

const Contact = () => {
  return (
    <section className="contact" id="contact">
      <div className="container">
        <div className="row">
          <div className="contact__wrapper">
            <div className="contact__information">
              <h3 className="contact__title">Kom in contact!</h3>
              <span className="contact__title--span">
                24 UUR, 7 DAGEN PER WEEK STAAN WIJ VOOR U KLAAR
              </span>
              <div className="contact__description">
                <p className="contact__description--para">
                  De medewerkers van DK allround-services staan 24 uur 7 dagen
                  in de week klaar voor al uw wensen. Wij bieden u diverse
                  mogelijkheden om ons te bereiken.
                </p>
                <br />
                <b className="contact__description--sub">Onze adresgegevens:</b>
                <p className="contact__description--adress">
                  Postbus 67 <br />
                  2600 AB Delft <br />
                  <br />
                  Rotterdamseweg 386 B10 <br />
                  2629 HG Delft
                </p>
                <br />
                <a href="tel:0852736063" className="contact__details">
                  <span className="contact__details--span">TEL</span>085 273 60
                  63
                </a>
                <br />
                <a href="mailto:info@dkgroep.nl" className="contact__details">
                  <span className="contact__details--span">EMAIL</span>
                  info@dkgroep.nl
                </a>
              </div>
            </div>
            <div className="contact__map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9827.990131703704!2d4.3741733!3d51.9886986!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5b68fb884b061%3A0x6b34c43b9a18d888!2sDK%20Allround%20Services!5e0!3m2!1snl!2snl!4v1678411830732!5m2!1snl!2snl"
                width="600"
                height="450"
                style={{ border: 0, width: "100%" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
