import React from "react";
import About from "../components/About";
import Contact from "../components/Contact";
import Landing from "../components/Landing";
import Quote from "../components/Quote";
import Services from "../components/Services";

const Home = () => {
  return (
    <>
      <Landing />
      <Quote />
      <About />
      <Services />
      <Contact />
    </>
  );
};

export default Home;
